import React, { useEffect, useRef, useState, useContext } from 'react';
import './cards.css';
import { useTimer } from 'react-timer-hook';
import Modal from '../Modal/Modal';
import { ethers } from 'ethers';
import values from '../../values.json';
import rabbitsAbi from '../../rabbitsAbi.json';
import { RefreshData, GameDetails } from '../../App';
import axios from 'axios';

const Cards = () => {
  const { state, setState } = useContext(RefreshData);
  const { gameDetail, platformDetail } = useContext(GameDetails);

  const [visibleModal1, setVisibleModal1] = useState(false);
  const [visibleModal2, setVisibleModal2] = useState(false);
  const [visibleModal3, setVisibleModal3] = useState(false);
  const [visibleModal4, setVisibleModal4] = useState(false);
  const [totalBet, setTotalBet] = useState({ 0: 0, 1: 0, 2: 0, 3: 0 });
  const [totalPeople, setTotalPeople] = useState({ 0: 0, 1: 0, 2: 0, 3: 0 });
  const [nextGameStartTime, setNextGameStartTime] = useState(0);
  const [modalNo, setModalNo] = useState();
  const modal = useRef();
  const cardss = useRef();
  const bidclose = useRef();
  let mins = 0;
  const [modalHide, setModalhide] = useState(false);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({ expiryTimestamp: gameDetail.timer, onExpire: () => console.warn('onExpire called') });

  const showModal = (value) => {
    console.log(value);
    if (value === 1) {
      setVisibleModal1(!visibleModal1);
      setVisibleModal2(false);
      setVisibleModal3(false);
      setVisibleModal4(false);
    }
    if (value === 2) {
      setVisibleModal2(!visibleModal2);
      setVisibleModal1(false);
      setVisibleModal3(false);
      setVisibleModal4(false);
    }
    if (value === 3) {
      setVisibleModal3(!visibleModal3);
      setVisibleModal2(false);
      setVisibleModal1(false);
      setVisibleModal4(false);
    }
    if (value === 4) {
      setVisibleModal4(!visibleModal4);
      setVisibleModal2(false);
      setVisibleModal3(false);
      setVisibleModal1(false);
    }
  };

  useEffect(() => {
    getTotalBet(1);
    getTotalBet(2);
    getTotalBet(3);
    getTotalBet(4);
    getTotalPeople(1);
    getTotalPeople(2);
    getTotalPeople(3);
    getTotalPeople(4);
    // getNextGameStartTime();
  }, [state]);

  // async function getNextGameStartTime() {
  //   try {
  //     const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/gamestarttime`);
  //     setNextGameStartTime(res.data.data.startTime);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // }

  async function getTotalBet(rabbitNumber) {
    try {
      const provider = new ethers.providers.JsonRpcProvider(values.rpcUrl);
      const contract = new ethers.Contract(values.bettingcontract, rabbitsAbi, provider);
      const _totalBet = await contract.getTotalBetSizeForRabbit(rabbitNumber);
      rabbitNumber--;
      // setTotalBet({...totalBet , [rabbitNumber]: parseFloat(ethers.utils.formatUnits(_totalBet, values.decimals)).toFixed(2)});
      setTotalBet((totalBet) => ({ ...totalBet, [rabbitNumber]: parseFloat(ethers.utils.formatUnits(_totalBet, values.decimals)).toFixed(2) }));
    } catch (error) {
      console.log('error', error);
    }
  }

  async function getTotalPeople(rabbitNumber) {
    try {
      const provider = new ethers.providers.JsonRpcProvider(values.rpcUrl);
      const contract = new ethers.Contract(values.bettingcontract, rabbitsAbi, provider);
      const _totalPeople = await contract.getTotalBetCountForRabbit(rabbitNumber);
      rabbitNumber--;
      // setTotalPeople({...totalPeople, [rabbitNumber]: parseInt(_totalPeople.toString())});
      setTotalPeople((totalPeople) => ({ ...totalPeople, [rabbitNumber]: parseInt(_totalPeople.toString()) }));
    } catch (error) {
      console.log('error', error);
    }
  }

  // make a component MyTimer
  function MyTimer() {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(nextGameStartTime - Date.now() / 1000); // 10 minutes timer
    const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({ expiryTimestamp: gameDetail.timer, onExpire: () => console.warn('onExpire called') });
    return (
      <div className="timer_container">
        <p>
          Game Starting In :{' '}
          <span className="orange">
            {' '}
            <span className="timer">
              <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </span>{' '}
          </span>{' '}
        </p>
      </div>
    );
  }

  return (
    <div className="card_div_outer">
      {gameDetail.timer - new Date().getTime() > 0 ? <MyTimer /> : <p className="timer_container">Game is Live</p>}

      <div className="cards_outer" ref={cardss}>
        <div className="cards_inner card1">
          <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
            <path
              d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
              fill="#F62C2C"
            />
          </svg>
          <p className="cards_title mont">BUGS</p>
          <div className="cards_desc>">
            <p className="cards_info ">
              Total Bet <span className="orange">: {gameDetail.bet1} </span>
            </p>
            <p className="cards_info">
              Total People Bet :<span className="orange"> {gameDetail.peoplebet1} </span>
            </p>
          </div>
          <button className="wallet_button wallet_button_bet" onClick={() => showModal(1)}>
            Place Bet
          </button>
        </div>

        <div className="cards_inner card2">
          <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
            <path
              d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
              fill="#3DA3DC"
            />
          </svg>
          <p className="cards_title mont">MIFFY</p>
          <div className="cards_desc>">
            <p className="cards_info">
              Total Bet : <span className="orange"> {gameDetail.bet2} </span>
            </p>
            <p className="cards_info">
              Total People Bet : <span className="orange"> {gameDetail.peoplebet2} </span>
            </p>
          </div>
          <button className="wallet_button wallet_button_bet" onClick={() => showModal(2)}>
            Place Bet
          </button>
        </div>

        <div className="cards_inner card3">
          <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
            <path
              d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
              fill="#FE7122"
            />
          </svg>
          <p className="cards_title mont">MUSK</p>
          <div className="cards_desc>">
            <p className="cards_info">
              Total Bet : <span className="orange"> {gameDetail.bet3} </span>
            </p>
            <p className="cards_info">
              Total People Bet : <span className="orange"> {gameDetail.peoplebet3}</span>
            </p>
          </div>
          <button className="wallet_button wallet_button_bet" onClick={() => showModal(3)}>
            Place Bet
          </button>
        </div>

        <div className="cards_inner card4">
          <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
            <path
              d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
              fill="#18D162"
            />
          </svg>
          <p className="cards_title mont">VB</p>
          <div className="cards_desc>">
            <p className="cards_info">
              Total Bet :<span className="orange"> {gameDetail.bet4} </span>{' '}
            </p>
            <p className="cards_info">
              Total People Bet : <span className="orange"> {gameDetail.peoplebet4} </span>
            </p>
          </div>
          <button className="wallet_button wallet_button_bet" onClick={() => showModal(4)}>
            Place Bet
          </button>
        </div>

        {visibleModal1 ? (
          <Modal
            color_bunnies="#F62C2C"
            rabbitNumber="0"
            totalBet={gameDetail.bet1}
            modal_outer={visibleModal1}
            setModalhide={setVisibleModal1}
            playername="BUGS"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
                <path
                  d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
                  fill="#F62C2C"
                />
              </svg>
            }
          />
        ) : (
          <></>
        )}
        {visibleModal2 ? (
          <Modal
            color_bunnies="#3DA3DC"
            rabbitNumber="1"
            totalBet={gameDetail.bet2}
            modal_outer={visibleModal2}
            setModalhide={setVisibleModal2}
            playername="MIFFY"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
                <path
                  d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
                  fill="#3DA3DC"
                />
              </svg>
            }
          />
        ) : (
          <></>
        )}
        {visibleModal3 ? (
          <Modal
            color_bunnies="#FE7122"
            rabbitNumber="2"
            totalBet={gameDetail.bet3}
            modal_outer={visibleModal3}
            setModalhide={setVisibleModal3}
            playername="MUSK"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
                <path
                  d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
                  fill="#FE7122"
                />
              </svg>
            }
          />
        ) : (
          <></>
        )}
        {visibleModal4 ? (
          <Modal
            color_bunnies="#18D162"
            rabbitNumber="3"
            totalBet={gameDetail.bet4}
            modal_outer={visibleModal4}
            setModalhide={setVisibleModal4}
            playername="VB"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="60" viewBox="0 0 43 60" fill="none">
                <path
                  d="M36.3657 38.4615C35.5671 37.9385 34.4614 36.5846 33.9393 35.9385C37.5943 30.7692 43 21.1385 43 9.23077C43 3.23077 40.7271 0 36.8571 0C32.1271 0 24.6943 6.33846 21.5 18.3692C18.3057 6.33846 10.8729 0 6.14286 0C2.27286 0 0 3.23077 0 9.23077C0 21.1385 5.40571 30.7692 9.06071 35.9385C8.53857 36.5846 7.43286 37.9385 6.63429 38.4615C5.09857 39.7846 1.53571 42.7385 1.53571 47.6923C1.53571 54.4923 7.03357 60 13.8214 60C18.5821 60 21.5 58.2769 21.5 58.2769C21.5 58.2769 24.4179 60 29.1786 60C35.9664 60 41.4643 54.4923 41.4643 47.6923C41.4643 42.7385 37.9014 39.7846 36.3657 38.4615ZM13.3607 31.3846C10.2586 26.7692 6.14286 18.8308 6.14286 9.23077C6.14286 7.69231 6.32714 6.76923 6.51143 6.24615C9.39857 7.2 16.4629 14.5846 16.8929 29.7538C15.4493 30.1231 14.3129 30.6769 13.3607 31.3846ZM16.8929 45.3846C16.0329 45.3846 15.3571 44.3385 15.3571 43.0769C15.3571 41.8154 16.0329 40.7692 16.8929 40.7692C17.7529 40.7692 18.4286 41.8154 18.4286 43.0769C18.4286 44.3385 17.7529 45.3846 16.8929 45.3846ZM21.5 53.8462C19.8107 53.8462 18.4286 51.6308 18.4286 50.7692C18.4286 49.9077 19.8107 49.2308 21.5 49.2308C23.1893 49.2308 24.5714 49.9077 24.5714 50.7692C24.5714 51.6308 23.1893 53.8462 21.5 53.8462ZM26.1071 45.3846C25.2471 45.3846 24.5714 44.3385 24.5714 43.0769C24.5714 41.8154 25.2471 40.7692 26.1071 40.7692C26.9671 40.7692 27.6429 41.8154 27.6429 43.0769C27.6429 44.3385 26.9671 45.3846 26.1071 45.3846ZM26.1071 29.7538C26.5371 14.5846 33.6014 7.2 36.4886 6.24615C36.6729 6.76923 36.8571 7.69231 36.8571 9.23077C36.8571 18.8308 32.7414 26.7692 29.6393 31.3846C28.6871 30.6769 27.5507 30.1231 26.1071 29.7538Z"
                  fill="#18D162"
                />
              </svg>
            }
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Cards;
