import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/LeftHome/Home';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import { createContext, useContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import '@rainbow-me/rainbowkit/styles.css';
import { configureChains, createClient, useAccount, WagmiConfig } from 'wagmi';
import { mainnet, bsc, bscTestnet } from 'wagmi/chains';
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { publicProvider } from 'wagmi/providers/public';
import rpcUrl from './values.json';
import { getDefaultWallets, connectorsForWallets } from '@rainbow-me/rainbowkit';
import axios from 'axios';
const projectId = process.env.REACT_APP_PROJECT_ID;

const { chains, provider } = configureChains([mainnet], [publicProvider()]);

const { wallets } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId,
  chains,
});

const connectors = connectorsForWallets([...wallets]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export const TransactionData = createContext();
export const GameDetails = createContext();
export const PlatformDetails = createContext();
export const GameRes = createContext();
export const Timer = createContext();
export const RefreshData = createContext();
export const AuthData = createContext();

function App() {
  // const [address, setAddress] = useState();
  const { address } = useAccount();
  const [previousAddress, setPreviousAddress] = useState('');
  const [state, setState] = useState(false);

  const [transaction, setTransaction] = useState([]);
  const [gameRes, setGameRes] = useState();
  const [loginDone, setLoginDone] = useState(window.localStorage.getItem('jwt') ? true : false);

  const [gameDetail, setGameDetail] = useState({
    gameId: 0,
    timer: 0,
    rewardToBeDistributed: 0,
    currentPoolSize: 0,
    totalUserBetted: 0,
    bet1: 0,
    bet2: 0,
    bet3: 0,
    bet4: 0,
    peoplebet1: 0,
    peoplebet2: 0,
    peoplebet3: 0,
    peoplebet4: 0,
  });

  const [platformDetail, setPlatformDetail] = useState({
    userAmount: 0,
  });

  const currentTotalBet = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalBet/0`)
      .then((res) => {
        console.log(res.data.data, 'current total bet on 0');
        setGameDetail((prev) => ({ ...prev, bet1: res.data.data }));
      })
      .catch((err) => console.log(err));
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalBet/1`)
      .then((res) => {
        console.log(res.data.data, 'current total bet on 1');
        setGameDetail((prev) => ({ ...prev, bet2: res.data.data }));
      })
      .catch((err) => console.log(err));

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalBet/2`)
      .then((res) => {
        console.log(res.data.data, 'current total bet on 2');
        setGameDetail((prev) => ({ ...prev, bet3: res.data.data }));
      })
      .catch((err) => console.log(err));

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalBet/3`)
      .then((res) => {
        console.log(res.data.data, 'current total bet on 3');
        setGameDetail((prev) => ({ ...prev, bet4: res.data.data }));
      })
      .catch((err) => console.log(err));
  };

  const currentTotalPeopleBet = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalPeopleBet/0`)
      .then((res) => {
        console.log(res.data.data, 'current total people bet 0');
        setGameDetail((prev) => ({ ...prev, peoplebet1: res.data.data }));
      })
      .catch((err) => console.log(err));
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalPeopleBet/1`)
      .then((res) => {
        setGameDetail((prev) => ({ ...prev, peoplebet2: res.data.data }));
      })
      .catch((err) => console.log(err));
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalPeopleBet/2`)
      .then((res) => {
        setGameDetail((prev) => ({ ...prev, peoplebet3: res.data.data }));
      })
      .catch((err) => console.log(err));
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalPeopleBet/3`)
      .then((res) => {
        setGameDetail((prev) => ({ ...prev, peoplebet4: res.data.data }));
      })
      .catch((err) => console.log(err));
  };

  const gettotalUserBetted = () => {
    console.log('address', address);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/totalUserBet/${address}`)
      .then((res) => {
        console.log(res.data.totalAmount, 'Total People Betted Till Now');
        setGameDetail((prev) => ({ ...prev, totalUserBetted: res.data.totalAmount }));
      })
      .catch((err) => {
        console.log('Error in Fetching Total User Betted');
      });
  };

  const getTotalUserToken = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/user/${address}`)
      .then((res) => {
        console.log('total User Token Deposited', res.data.data);
        setPlatformDetail((prev) => ({ ...prev, userAmount: res?.data?.data?.balance }));
      })
      .catch((err) => console.log(err));
  };

  const getCurrentGameId = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/currentGameId`)
      .then((res) => {
        console.log(res.data.gameId);
        setGameDetail((prev) => ({ ...prev, gameId: res.data.gameId }));
      })
      .catch((err) => console.log(err));
  };

  const getGameData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/game/${gameDetail.gameId}`)
      .then((res) => {
        console.log(res);
        console.log(res.data.data.totalAmount, res.data.data.startTime);
        setGameDetail((prev) => ({
          ...prev,
          currentPoolSize: res.data.data.totalAmount,
          timer: res.data.data.startTime,
          rewardToBeDistributed: res.data.data.totalAmount - (res.data.data.totalAmount / 100) * 10,
        }));
        console.log(gameDetail)
      })
      .catch((err) => console.log(err));
  };

  const getGameRes = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/winnerList`)
      .then((res) => {
        setGameRes(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getTransaction = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/txnHistory`)
      .then((res) => {
        console.log('Txn History', res.data.data.txnHistory);
        setTransaction(res.data.data.txnHistory);
      })
      .catch((err) => console.log(err));
  };

  // let signer;

  // const getAddress = async () => {
  //   try {
  //     const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  //     signer = provider.getSigner();
  //     setAddress(await signer.getAddress());
  //     console.log(signer, provider, 'provider');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    // getAddress();
    console.log(address);
    getCurrentGameId();
    // getGameData();
    currentTotalBet();
    currentTotalPeopleBet();
    getGameRes();
    getTransaction();
    getTotalUserToken();
    gettotalUserBetted();
  }, [state, address]);

  useEffect(() => {
    getGameData();
  }, [gameDetail.gameId]);


  useEffect(() => {
    if (previousAddress === '') {
      setPreviousAddress(address);
      return;
    }

    if (previousAddress !== address) {
      window.localStorage.removeItem('jwt');
      setLoginDone(false);
      setPreviousAddress(address);
    }
  }, [address]);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        initialChain={1}
        theme={darkTheme({
          accentColor: '#ED6622',
          accentColorForeground: 'white',
          borderRadius: 'small',
          fontStack: 'system',
          overlayBlur: 'small',
        })}
      >
        <TransactionData.Provider value={{ transaction: transaction || [] }}>
          <GameDetails.Provider value={{ gameDetail, platformDetail }}>
            <GameRes.Provider value={{ gameRes }}>
              <RefreshData.Provider value={{ state, setState }}>
                <AuthData.Provider value={{ loginDone, setLoginDone }}>
                  <div className="App">
                    <Navbar />
                    <Home />
                  </div>
                </AuthData.Provider>
              </RefreshData.Provider>
            </GameRes.Provider>
          </GameDetails.Provider>
        </TransactionData.Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
