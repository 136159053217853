import React, { forwardRef, useEffect, useState } from 'react';
import chatLogo from '../../assets/images/chatLogo.png';
import './chat.css';
import Jdenticon from 'react-jdenticon';

const Chat = ({ message }, ref) => {
  const time = message.timestamp;

  return (
    <div className="chat_outer" ref={ref}>
      <div className="chat_logo_container">
        <Jdenticon size="32" value={message.address} />
      </div>
      <div className="chat_content">
        <div className="top_content">
          <div className="address">
            <p>{message.address?.toString().slice(0, 6) + '...' + message.address?.toString().slice(-8, -1)}</p>
          </div>
          <div className="date">
            <p>{time.toString().slice(11, 19)} UTC</p>
          </div>
        </div>
        <div className="message_container">
          <p>{message.text}</p>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Chat);
