import React, { useEffect } from 'react'
import { ethers } from 'ethers'
import values from '../../values.json';


const Transact = ({transaction}) => {
  const names = ["Bugs", "Miffy", "Musk", "VB", "Cancelled"];
  useEffect(()=>{
    console.log(transaction, "sadasdasdasdasdasd")
  },[])
  return (
    <div className='content_table'>
        <p>#{transaction.gameId.toString()}</p>
        <p>{(transaction?.address?.toString())?.substring(0, 5) + "..." + (transaction?.address?.toString())?.slice(-3) }</p>
        <p>{names[transaction.position.toString()]}</p>
        <p>{transaction.amount.toFixed(2)}</p>
    </div>
  )
}

export default Transact