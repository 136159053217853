import React, { useEffect, useRef, useState } from 'react';
import './modal.css';
import { ethers } from 'ethers';
import values from '../../values.json';
import rabbitsAbi from '../../rabbitsAbi.json';
import tokenAbi from '../../tokenAbi.json';
import { createContext, useContext } from 'react';
import { AuthData, GameDetails, RefreshData } from '../../App';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from 'axios';

const Modal = ({ playername, setModalhide, modal_outer, icon, color_bunnies, rabbitNumber, totalBet }) => {
  const modalref = useRef();
  const hideModal = () => {
    setModalhide(false);
  };
  const { state, setState } = useContext(RefreshData);
  const { platformDetail } = useContext(GameDetails);
  const { loginDone , setLoginDone} = useContext(AuthData);

  let notyf;
  useEffect(() => {
    notyf = new Notyf({
      duration: 1000,
      position: {
        x: 'right',
        y: 'top',
      },
    });
  });
  console.log(state);

  const [bettingAmount, setBettingAmount] = useState(0);
  const [balanceOfUser, setBalanceOfUser] = useState(platformDetail.userAmount);

  async function approveToken() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(values.bettingToken, tokenAbi, signer);
      const _bettingAmount = ethers.utils.parseUnits(bettingAmount, values.decimals);

      // check alread approved or not
      const userAddress = await signer.getAddress();
      const allowance = await contract.allowance(userAddress, values.bettingcontract);
      console.log('allowance', allowance.toString());
      if (allowance.gte(_bettingAmount)) return true;

      const transaction = await contract.approve(values.bettingcontract, _bettingAmount);
      console.log('transaction', transaction.hash);
      await transaction.wait();
      console.log('transaction', transaction.hash);
      notyf.success('Token Approved');
      return true;
    } catch (error) {
      console.log('error', error);
      notyf.error('Error While Approving');

      return false;
    }
  }

  async function placeBet() {
    try {
      if (!loginDone) {
        return notyf.error('Please login!');
      }
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/place-bet`,
        {
          position: +rabbitNumber,
          amount: +bettingAmount,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: JSON.parse(window.localStorage.getItem('jwt')),
          },
        }
      );
      setState(!state);
      notyf.success('Transaction Completed');
    } catch (error) {
      console.log('error', error);
      notyf.error("You Can't Bet Now");
      if (error?.response?.data?.error === 'Token expired') {
        setLoginDone(false);
        notyf.error('Please log in');
      }
    }
  }

  // async function getBalanceOfUser () {
  //   try {
  //   const provider = new ethers.providers.JsonRpcProvider(values.rpcUrl);
  //   const contract = new ethers.Contract(values.bettingToken, tokenAbi, provider);
  //   const _provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const signer = _provider.getSigner();
  //   const userAddress = await signer.getAddress();
  //   const balance = await contract.balanceOf(userAddress);
  //   setBalanceOfUser(parseFloat(ethers.utils.formatUnits(balance, values.decimals)).toFixed(2));
  //   } catch (error) {
  //     console.log ("error", error);
  //   }
  // }
  // useEffect(()=>{
  // getBalanceOfUser();
  // }, [])

  useEffect(() => {
    modal_outer ? (modalref.current.style.background = 'rgba(0,0,0,0.75)') : (modalref.current.style.background = 'rgba(0,0,0,0.75)');
  }, [modal_outer]);

  return (
    <div className="modalBack">
      <div className="modal_outer" ref={modalref}>
        <div className="modal_inner">
          <div className="modal_heading">
            <h2>
              PLACING A BET ON{' '}
              <span style={{ color: color_bunnies }} className="mont montweight">
                {playername}
              </span>
              {icon}
            </h2>
          </div>
          <div className="input_modal">
            <input
              placeholder="Enter Amount In $RABBITS"
              onChange={(e) => {
                setBettingAmount(e.target.value);
              }}
            />
          </div>
          <div className="info_box">
            <p className="cards_info">
              Your Balance :<span className="orange">{balanceOfUser} </span>{' '}
            </p>
            <p className="cards_info">
              Total Bet : <span className="orange">{totalBet} </span>
            </p>
          </div>
          <div className="modal_buttons">
            <div>
              {' '}
              <button className="placebet_modal" onClick={placeBet}>
                Place Bet
              </button>
            </div>
            <div>
              {' '}
              <button className="cancel_modal" onClick={() => hideModal()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
