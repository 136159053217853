import React, { useContext } from 'react';
import { useAccount, useProvider, useSigner } from 'wagmi';
import logo from '../../assets/images/logo.png';
import './navbar.css';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { AuthData } from '../../App';

const Navbar = () => {
  const [nonce, setnonce] = useState('');
  const { loginDone, setLoginDone } = useContext(AuthData);

  async function getNonce() {
    try {
      let headersList = {
        Accept: '*/*',
        'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
      };

      let reqOptions = {
        url: 'https://fragrant-magnificent-attempt.glitch.me/nonce',
        method: 'GET',
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      setnonce(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const { data: signer } = useSigner();
  const provider = useProvider();
  const { address } = useAccount();

  async function signMessage() {
    try {
      if (address) {
        const messege = `this nonce is signed using metamask ${nonce}`;
        const signMessage = await signer.signMessage(messege);

        let headersList = {
          Accept: '*/*',
          'Content-Type': 'application/json',
        };

        try {
          let bodyContent = JSON.stringify({
            address: address,
            message: messege,
            signedMessage: signMessage,
          });

          let reqOptions = {
            url: `${process.env.REACT_APP_SERVER_URL}/login`,
            method: 'POST',
            headers: headersList,
            data: bodyContent,
          };

          let response = await axios.request(reqOptions);
          window.localStorage.setItem('jwt', JSON.stringify(response.data));
          setLoginDone(true);
        } catch (error) {
          console.log(error);
        }
      } else {
        alert('connect wallet');
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNonce();
  }, []);

  return (
    <div className="navbar_outer">
      <div className="navbar_inner">
        <div className="logo_container">
          <img src={logo} alt="rabbits club logo" className="logo" />
          <p className="title mont">RABBITS CLUB</p>
        </div>

        <div className="wallet_container">
          <a target="_black" rel="noreferrer" href="https://app.uniswap.org/#/tokens/ethereum/0x3576eff2be8b1a31782b3e8dfd962a6ddb087890">
            <button className="wallet_button navbarBuy">Buy $RABBITS</button>
          </a>

          <div className="connect_div">
            {!signer ? (
              <ConnectButton
                chainStatus={{ smallScreen: 'none', largeScreen: 'icon' }}
                accountStatus={{ smallScreen: 'avatar', largeScreen: 'full' }}
                className="wallet_button"
                label="Connect"
                showBalance={false}
              />
            ) : !loginDone ? (
              <button className="wallet_button login_button" onClick={() => signMessage()}>
                Login
              </button>
            ) : (
              <button
                className="wallet_button login_button"
                onClick={() => {
                  window.localStorage.removeItem('jwt');
                  setLoginDone(false);
                }}
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
