import React from 'react';
import "./info.css";


const Info = ({label1, label2, label3, label1_value, label2_value, label3_value}) => {
  return (
    <div className='info_outer'>
        <div className='info_inner'>
            <div className='box'>
                <p>{label1} : <span className='orange'>{label1_value}</span></p>
            </div>
            <div className='box'>
                <p>{label2} : <span className='orange'> {label2_value}</span></p>
            </div>
            <div className='box'>
                <p>{label3} : <span className='orange'>{label3_value}</span></p>
            </div>
        </div>
    </div>
  )
}

export default Info