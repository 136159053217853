import React, { useState, useEffect } from 'react';
import './deposit.css';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { createContext, useContext } from 'react';
import { ethers } from 'ethers';
import values from '../../values.json';
import rabbitsAbi from '../../rabbitsAbi.json';
import tokenAbi from '../../tokenAbi.json';
import { RefreshData, GameDetails, AuthData } from '../../App';
import axios from 'axios';

const Deposit = () => {
  const { gameDetail, platformDetail } = useContext(GameDetails);
  const { loginDone, setLoginDone } = useContext(AuthData);
  console.log(gameDetail, platformDetail, 'From deposit');
  const { state, setState } = useContext(RefreshData);
  const [bettingAmount, setBettingAmount] = useState(0);
  const [balanceOfUser, setBalanceOfUser] = useState(0);
  const [despositValue, setDepositValue] = useState(0);
  const [txvalue, setTxvalue] = useState(0);
  

   
  let notyf;
  useEffect(() => {
    notyf = new Notyf({
      duration: 1000,
      position: {
        x: 'right',
        y: 'top',
      },
    });
  });

  const deposit_backend = async (tx) => {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}/deposit`,
      {
        transactionHash: tx,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: JSON.parse(window.localStorage.getItem('jwt')),
        },
      }
    );
  };

  const withdraw_backend = async (amount) => {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}/withdraw`,
      {
          amount,
          
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: JSON.parse(window.localStorage.getItem('jwt')),
        },
      }
    );
    setTxvalue('')

  };

  const handleDepositValue = (e) => {
    setDepositValue(e.target.value);
  };
  const handleTxValue = (e) => {
    setTxvalue(e.target.value);
  };

  async function approveToken() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(values.bettingToken, tokenAbi, signer);
      const _bettingAmount = ethers.utils.parseUnits(despositValue, values.decimals);

      // check alread approved or not
      const userAddress = await signer.getAddress();
      const allowance = await contract.allowance(userAddress, values.bettingcontract);
      console.log('allowance', allowance.toString());
      if (allowance.gte(_bettingAmount)) return true;

      const transaction = await contract.approve(values.bettingcontract, _bettingAmount);
      console.log('transaction', transaction.hash);
      await transaction.wait();
      console.log('transaction', transaction.hash);
      notyf.success('Token Approved');
      return true;
    } catch (error) {
      console.log('error in approve');
      console.dir(error);
      notyf.error('Error While Approving');

      return false;
    }
  }

  async function depositToken() {
    try {
      if (!loginDone) {
        return notyf.error('Please login first');
      }
      const _approve = await approveToken(values.bettingToken);
      if (_approve === false) return;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(values.bettingcontract, rabbitsAbi, signer);
      const despositAmount = ethers.utils.parseUnits(despositValue, values.decimals);
      console.log('_bettingAmount', despositAmount, 'value', 1);
      const transaction = await contract.a(despositAmount);
      console.log('transaction', transaction.hash);
      await transaction.wait();
      console.log('transaction', transaction.hash);
      await deposit_backend(transaction.hash);
      setState(!state);
      notyf.success('Transaction Completed');
      setDepositValue('')
      
    } catch (error) {
      console.dir(error);
      console.log('error in deposit token');
      notyf.error("You Can't Bet Now");
      if (error?.response?.data?.error === 'Token expired') {
        setLoginDone(false);
        notyf.error('Please log in');
      }
    }
  }

  async function withdraw() {
    try {
      if (!loginDone) {
        return notyf.error('Please login first');
      }
      await withdraw_backend(despositValue);
      setState(!state);
      notyf.success('Transaction Completed');
    } catch (error) {
      console.dir(error);
      console.log('error in withdraw token');
      notyf.error("You Can't Withdraw Now");
      if (error?.response?.data?.error === 'Token expired') {
        setLoginDone(false);
        notyf.error('Please log in');
      }
    }
  }

  const [showcheck, setShowCheck] = useState(false)

  const checkboxChange = () => { 
    setShowCheck(!showcheck)

    
  }; 
  return (
    
    <div className="deposit_outer">
      <div className="deposit_inner">
        <input className="input_deposit" value={despositValue} placeholder="Enter Amount..." onChange={(e) => handleDepositValue(e)} />

        <div className="deposit_label">
        {showcheck ? <input className="input_deposit" value={txvalue} placeholder="Enter Transaction Hash..." onChange={(e) => handleTxValue(e)} />: <></> } 

          <p>
            $RABBITS Deposited: <span className="orange">{platformDetail.userAmount}</span>
          </p>

        </div>

        <div className="button_div">
        {showcheck === false ?  <button className="deposit_button wallet_button" onClick={() => depositToken()}>
            Deposit
          </button> :  <button className="deposit_button wallet_button" onClick={() => deposit_backend(txvalue)}>
            Deposit With Hash
          </button> }
          <button className="deposit_button wallet_button" onClick={() => withdraw()}>
            Withdraw
          </button>

        </div>
        <div className='checkbox_container'>
        <input type="checkbox" id="" onChange={checkboxChange}/> Deposited But Not Updated?
        </div>
      </div>
    </div>
  );
};

export default Deposit;
