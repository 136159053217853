import React, { useEffect, useState, useContext  } from 'react'
import Info from '../Info/Info'
import Sidebar from '../Sidebar/Sidebar'
import "./home.css";
import Player from '../Player/Player';
import Cards from '../Cards/Cards';
import Transaction from '../Transactions/Transaction';
import { ethers } from 'ethers';
import values from '../../values.json';
import rabbitsAbi from '../../rabbitsAbi.json';
import Footer from '../Footer/Footer';
import { RefreshData, GameDetails } from '../../App';
import Deposit from '../depositWithdraw/Deposit';


const Home = () => {
  const { state, setState } = useContext(RefreshData);
  const { gameDetail, platformDetail } = useContext(GameDetails);

  const [totalGamesDone, setTotalGamesDone] = useState(0);
  const [totalRewardsDistributed, setTotalRewardsDistributed] = useState(0);
  const [currentMCAP, setCurrentMCAP] = useState(0);
  const [currentPoolSize, setCurrentPoolSize] = useState(0);
  const [rewardToBeDistributed, setRewardToBeDistributed] = useState(0);



  useEffect(() => {
    getCurrentMCAP();
    console.log(state + "from Info")
    console.log(gameDetail, platformDetail, "testing Context")
  }, [state]);





  async function getCurrentMCAP () {
    const url = "https://api.dexscreener.com/latest/dex/tokens/0x3576EFf2be8B1A31782b3E8dfD962a6ddb087890";

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        const _marketcap = json.pairs[0].priceUsd * 10000000;
        setCurrentMCAP("$" + parseFloat((_marketcap).toFixed(2)).toLocaleString());
        console.log ("_marketcap", _marketcap);
        console.log(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }


  

  return (
    <div className="home_outer">
      <div className="left_home">
        <Info label1="Total Games Done" label2="Your Total Bet" label3="Current MCAP" label1_value={gameDetail.gameId - 1} label2_value={gameDetail.totalUserBetted} label3_value={currentMCAP} />
        <Player gameId= {gameDetail.gameId} />
        <Cards />
        <Info label1="GameId" label2="Reward to be Distributed" label3="Current Pool Size" label1_value={gameDetail.gameId} label2_value={gameDetail.rewardToBeDistributed} label3_value={gameDetail.currentPoolSize} />
        <Deposit />
        <Transaction />
        <Footer />
      </div>
      <div className="right_home">
        <Sidebar />
      </div>
      <a target="_black" rel="noreferrer" href="https://app.uniswap.org/#/tokens/ethereum/0x3576eff2be8b1a31782b3e8dfd962a6ddb087890">
        <button className="wallet_button buyButton">Buy $RABBITS</button>
      </a>
    </div>
  );
};

export default Home;
