// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore'


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyADbMs8VN6Y_jWyWZrpWRh1u8akDlN0E64",
    authDomain: "rabbits-a0ddb.firebaseapp.com",
    projectId: "rabbits-a0ddb",
    storageBucket: "rabbits-a0ddb.appspot.com",
    messagingSenderId: "735858540702",
    appId: "1:735858540702:web:e346e753462af482eb3b8f"
  };

  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)