import React, { useState, useRef, useEffect, createRef } from 'react';
import './sidebar.css';
import Chat from '../chat/Chat';
import { query, QuerySnapshot, collection, onSnapshot, orderBy, addDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { ethers } from 'ethers';
import { Signer } from 'ethers';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useSigner, useProvider, useContract } from 'wagmi';
import io from 'socket.io-client';

const Sidebar = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [close, setClose] = useState(false);
  const sendMessage = useRef();
  const sidebar = useRef();
  const [wallet, setWallet] = useState('0xASDASD');
  const [socket, setSocket] = useState(null);
  const [totalOnline, setTotalOnline] = useState(0);

  const [input, setInput] = useState('');
  const scroll = useRef();
  const lastMessage = useRef();

  const { data: signer, isError, isLoading } = useSigner();
  
  useEffect(() => {
    refreshData(signer);
  }, [signer]);

  // scroll when new message arrives
  useEffect(() => {
    if (lastMessage.current) {
      lastMessage.current.scrollIntoView({ smooth: true });
    }
  }, [chatMessages]);

  useEffect(() => {
    try {
      const socket = io('https://skinny-bony-limit.glitch.me/');
      setSocket(socket);

      socket.on('connect', () => {
        console.log('Socket connected successfully!');
      });

      socket.on('error', (error) => {
        console.error('Socket connection error:', error);
      });

      // Listen for chat messages from the server
      socket.on('chatMessage', (message) => {
        setChatMessages((prevMessages) => [...prevMessages, message]);
    
      });

      // Listen for online users count from the server
      socket.on('onlineUsersCount', (onlineUsers) => {
        setTotalOnline(onlineUsers);
      });

      // Listen for past chat messages from the server
      socket.on('pastChatMessages', (pastMessages) => {
        setChatMessages(pastMessages.reverse());
      });
    } catch (error) {
      console.log(error, 'socket useeffect');
    }

    return () => {
      try {
        socket.disconnect();
      } catch (error) {
        console.log('cleanup', error);
      }
    };
  }, []);

  useEffect(() => {
    sidebar.current.style.scrollTop = sidebar.current.style.scrollHeight;
  }, [sidebar]);

  async function refreshData(signer) {
    if (signer) {
      const address = await signer.getAddress();
      setWallet(address);
      console.log(address);
    }
  }

  const sendMessageFunc = async (e) => {
    try {
      console.log('message sent');
      e.preventDefault();
      if (input === '') {
        alert('Please enter a valid message');
        return;
      }

      const currentTimestamp = Date.now();
      const currentUTCTime = new Date(currentTimestamp);
      // // Convert the UTC time to a readable string representation
      const utcTimeString = currentUTCTime.toISOString();

      socket.emit('chatMessage', { text: input, address: wallet, timestamp: utcTimeString });

      setInput('');
      scroll.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.log('sendmessage', error);
    }
  };

  const handleClick = () => {
    setClose(!close);
    {
      close ? (sidebar.current.style.right = '-100%') : (sidebar.current.style.right = '0%');
    }
    {
      close ? (sendMessage.current.style.right = '-100%') : (sendMessage.current.style.right = '0%');
    }
    {
      close ? (document.body.style.overflowY = 'auto') : (document.body.style.height = 'hidden');
    }
  };
  return (
    <>
      <div className="ham">
        {close ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={(e) => {
              handleClick();
            }}
          >
            <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="white" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={(e) => {
              handleClick();
            }}
          >
            <path
              d="M6 14H14V12H6V14ZM6 11H18V9H6V11ZM6 8H18V6H6V8ZM2 22V4C2 3.45 2.196 2.979 2.588 2.587C2.98 2.195 3.45067 1.99934 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.804 17.021 21.412 17.413C21.02 17.805 20.5493 18.0007 20 18H6L2 22Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      <div className="sidebar_outer" ref={sidebar}>
        
        {chatMessages &&
          chatMessages.map((message, i) => {
            if (chatMessages.length === i + 1) {
              return <Chat key={message.id || i} message={message} ref={lastMessage} />;
            }
            return <Chat key={message.id || i} message={message} />;
          })}
        <div className="sendMessage_container" ref={sendMessage}>
          <input placeholder="Your Message Here" value={input} onChange={(e) => setInput(e.target.value)} type="text" />
          <div className="button_container">
            <p>
              Total Online : <span className="green">{totalOnline}</span>
            </p>
            {signer ? (
              <buton className="wallet_button wallet_send" type="submit" onClick={(e) => sendMessageFunc(e)}>
                Send Message
              </buton>
            ) : (
              <ConnectButton
                chainStatus={{ smallScreen: 'none', largeScreen: 'icon' }}
                accountStatus={{
                  smallScreen: 'avatar',
                  largeScreen: 'full',
                }}
                className="wallet_button"
                label="Connect"
                showBalance={false}
              />
            )}
            <span ref={scroll}></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
