import React from 'react';
import "./player.css";

const Player = ({ gameId }) => {
  return (
    <div className='player_outer'>
        <div className='gameId_div'>
            <p className='gameId'>
                Game Id - <span className='orange'>#{gameId}</span>
            </p>
            <div className='player_main'>
            <iframe src="https://player.twitch.tv/?channel=therabbitsclub&parent=www.therabbits.bet" frameborder="0" allowfullscreen="true" height="100%" width="100%"></iframe>

            </div>
        </div>
    </div>
  )
}

export default Player