import React, { useEffect, useState, useContext } from 'react';
import "./transaction.css";
import Transact from './Transact';
import values from '../../values.json';
import rabbitsAbi from '../../rabbitsAbi.json';
import tokenAbi from '../../tokenAbi.json';
import { ethers } from 'ethers';
import { RefreshData, TransactionData, GameRes } from '../../App';
import { Notyf } from 'notyf';



const Transaction = () => {

    let notyf;
    useEffect(()=>{
      notyf = new Notyf({
        duration: 1000,
        position: {
          x: 'right',
          y: 'top',
        }
        });

    })

    const { gameRes } = useContext(GameRes);
    const { transaction } = useContext(TransactionData);
    const { state, setState } = useContext(RefreshData);
    const [transactions, setTransactions] = useState([]);
    const [userClaimableAmount, setUserClaimableAmount] = useState(0);
    const names = ["Bugs", "Miffy", "Musk", "VB", "Cancelled"];

    useEffect(() => {
        getUserClaimableAmount();
        console.log(transaction, gameRes, "transaction data from  tranaction component")
      }, [state]);

   
 

    async function getUserClaimableAmount () {
        try {
        const provider = new ethers.providers.JsonRpcProvider(values.rpcUrl);
        const contract = new ethers.Contract(values.bettingcontract, rabbitsAbi, provider);
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = _provider.getSigner();
        const userAddress = await signer.getAddress();
        const _userClaimableAmount = await contract.claimableReward(userAddress);
        setUserClaimableAmount(parseFloat(ethers.utils.formatUnits(_userClaimableAmount, values.decimals)).toFixed(2));
        } catch (error) {
          console.log ("error", error);
        }
    }

    // async function claimReward () {
    //     try {
    //       const provider = new ethers.providers.Web3Provider(window.ethereum);
    //       const signer = provider.getSigner();
    //       const contract = new ethers.Contract(values.bettingcontract, rabbitsAbi, signer);
    //       const transaction = await contract.claimReward();
    //       console.log ("transaction", transaction.hash);
    //       await transaction.wait();
    //       console.log ("transaction", transaction.hash);
    //       setState(!state);
    //       notyf.success("Claimed Successfully")

    //     } catch (error) {
    //       console.log ("error", error);
    //       notyf.error("Error While Clamming")
    //     }
    // }
   
  return (

    <div className='transaction_outer'>
        <div className='transaction_left'>
            <div className='result_box'>
                <p className='result_title'>24H GAME RESULTS</p>
                <div className='result_table'>
                    {gameRes && gameRes.map((result, index) => {
                    return(
                    <div className='result_row'>
    <div className='result_container'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 18 25" fill="none">
    <path d="M15.2229 16.0256C14.8886 15.8077 14.4257 15.2436 14.2071 14.9744C15.7371 12.8205 18 8.80769 18 3.84615C18 1.34615 17.0486 0 15.4286 0C13.4486 0 10.3371 2.64103 9 7.65385C7.66286 2.64103 4.55143 0 2.57143 0C0.951429 0 0 1.34615 0 3.84615C0 8.80769 2.26286 12.8205 3.79286 14.9744C3.57429 15.2436 3.11143 15.8077 2.77714 16.0256C2.13429 16.5769 0.642857 17.8077 0.642857 19.8718C0.642857 22.7051 2.94429 25 5.78571 25C7.77857 25 9 24.2821 9 24.2821C9 24.2821 10.2214 25 12.2143 25C15.0557 25 17.3571 22.7051 17.3571 19.8718C17.3571 17.8077 15.8657 16.5769 15.2229 16.0256ZM5.59286 13.0769C4.29429 11.1538 2.57143 7.84615 2.57143 3.84615C2.57143 3.20513 2.64857 2.82051 2.72571 2.60256C3.93429 3 6.89143 6.07692 7.07143 12.3974C6.46714 12.5513 5.99143 12.7821 5.59286 13.0769ZM7.07143 18.9103C6.71143 18.9103 6.42857 18.4744 6.42857 17.9487C6.42857 17.4231 6.71143 16.9872 7.07143 16.9872C7.43143 16.9872 7.71429 17.4231 7.71429 17.9487C7.71429 18.4744 7.43143 18.9103 7.07143 18.9103ZM9 22.4359C8.29286 22.4359 7.71429 21.5128 7.71429 21.1538C7.71429 20.7949 8.29286 20.5128 9 20.5128C9.70714 20.5128 10.2857 20.7949 10.2857 21.1538C10.2857 21.5128 9.70714 22.4359 9 22.4359ZM10.9286 18.9103C10.5686 18.9103 10.2857 18.4744 10.2857 17.9487C10.2857 17.4231 10.5686 16.9872 10.9286 16.9872C11.2886 16.9872 11.5714 17.4231 11.5714 17.9487C11.5714 18.4744 11.2886 18.9103 10.9286 18.9103ZM10.9286 12.3974C11.1086 6.07692 14.0657 3 15.2743 2.60256C15.3514 2.82051 15.4286 3.20513 15.4286 3.84615C15.4286 7.84615 13.7057 11.1538 12.4071 13.0769C12.0086 12.7821 11.5329 12.5513 10.9286 12.3974Z" fill="#4ECB71"/>
    </svg>
    <p>Game Id - {result.gameId} </p>
{console.log(result)}
    </div>
    <div className="winner_rabbit"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
        <path d="M6.70833 20.125V18.2083H10.5417V15.2375C9.75903 15.0618 9.06008 14.7302 8.44483 14.2428C7.82958 13.7553 7.37853 13.1445 7.09167 12.4104C5.89375 12.2667 4.89133 11.7437 4.08442 10.8416C3.2775 9.93951 2.87436 8.88119 2.875 7.66667V6.70833C2.875 6.18125 3.06283 5.72987 3.4385 5.35421C3.81417 4.97854 4.26522 4.79103 4.79167 4.79167H6.70833V2.875H16.2917V4.79167H18.2083C18.7354 4.79167 19.1868 4.9795 19.5625 5.35517C19.9381 5.73083 20.1256 6.18189 20.125 6.70833V7.66667C20.125 8.88056 19.7215 9.93887 18.9146 10.8416C18.1077 11.7444 17.1056 12.2673 15.9083 12.4104C15.6208 13.1451 15.1695 13.7562 14.5542 14.2437C13.939 14.7312 13.2403 15.0624 12.4583 15.2375V18.2083H16.2917V20.125H6.70833ZM6.70833 10.35V6.70833H4.79167V7.66667C4.79167 8.27361 4.96736 8.82082 5.31875 9.30829C5.67014 9.79576 6.13333 10.143 6.70833 10.35ZM16.2917 10.35C16.8667 10.1424 17.3299 9.79481 17.6813 9.30733C18.0326 8.81986 18.2083 8.27297 18.2083 7.66667V6.70833H16.2917V10.35Z" fill="white"/>
        </svg> <p className="winner_rabbit_name">{names[result.announcedWinner]}</p>
    </div>
    </div>
                    )}

                    ).reverse()}
                </div>
         
            </div>
            {/* <div className='claim_outer'>
                    <div className='claim_inner'>
                        <div className='claim_info'>
                            <p className='label1_claim'>Total Claimed</p>
                            <p className='orange'>{userClaimableAmount}</p>
                        </div>
                        <div className='claim_button_div'>
                            <button className='wallet_button button_claim' onClick={claimReward}>Claim Now</button>
                        </div>
                    </div>
                </div> */}
        </div>




        
        <div className='transaction_right'>
        <div className='result_box'>
                <p className='result_title'>Recent Transaction</p>
                <div className='transaction_table'>
              
                    <div className='transaction_row'>
                
                      
                    <div className='Heading_table'>
                            <p>GameID</p>
                            <p>ADDRESS</p>
                            <p>BETTED</p>
                            <p>AMOUNT</p>
                        </div>
                       {transaction.map((transaction) => <Transact transaction={transaction} />).reverse()}
                       
                    </div>
                </div>
    </div>
    </div>
    </div>
  )
}

export default Transaction